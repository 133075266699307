import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Nav,
  Button,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import io from "socket.io-client";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import helpers from "../utils/helpers";
import Heading from "../components/Heading";
import constants from "../utils/constants";
const Header = () => {
  const [socket, setSocket] = useState(null);
  const { register, handleSubmit } = useForm({ mode: "onChange" });
  const location = useLocation();
  const parmas = useParams();
  const current_url = location.pathname.split("/")[1];
  let {
    user,
    logoutUser,
    user_coins,
    setUserCoins,
    getCoins,
    isRefereshLoader,
    setRefereshLoader,
  } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormSubmit = (form_data = {}) => {
    document.documentElement.style.setProperty("--primary", "#b36c3d");
  };
  useEffect(() => {
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${user._id ? user._id : 112233
      }`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins(message.results.totalCoins);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    newSocket.emit("getCoins", { user_id: user._id });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);
  const [expanded, setExpanded] = useState(false);
  const [check, setCheck] = useState(
    parmas?.id && parmas.user_type ? true : false
  );
  return (
    <div>
      <header>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=0" />
        </Helmet>
        <div className="top-header">
          <Container fluid>
            <Row className="align-items-center">
              {/* <Col sm={4}>
                <div className="logo">
                  <a href="/" className="d-inline-block">
                    <img src="./assets/images/velki-logo.png" alt="" />
                  </a>
                </div>
              </Col> */}
              <Heading />
              {!isEmpty(user) ? (
                <Col sm={6}>
                  <div className="text-sm-end text-center top-header-owner">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <span>
                          {/* {user?.userType == "super_admin"
                            ? "owner"
                            : user?.userType == "agent" ? "Master" : user?.userType || ""} */}
                            {constants?.layerPanel[user?.userType || ""]}
                        </span>{" "}
                        <strong>{user?.username || ""}</strong>
                      </li>
                      {isRefereshLoader ? (
                        <li>
                          <p className="loading-bar" id="menuRefreshLoading">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </p>
                        </li>
                      ) : (
                        <li>
                          <a href="#" className="text-decoration-none">
                            <span>Main</span>{" "}
                            <strong>
                              PBU {helpers.currencyFormat(user_coins)}
                            </strong>
                          </a>
                          <a
                            href="#"
                            className="btn"
                            onClick={() => getCoins()}
                          >
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" height="1.25rem" width="1.25rem">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                              </svg>
                            </span>
                          </a>
                          <a
                            href="#"
                            className="btn"
                            onClick={logoutUser}>
                            <span> Logout
                              <i className="fa-solid fa-right-from-bracket" style={{ marginLeft: `8px` }}></i>
                            </span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>

        <div className="main-header">
          <div className="logo">
            <a href="/" className="d-inline-block text-center">
              <img src="./assets/images/logo.png" alt="" className="side-logo" />
            </a>
          </div>
          <Container fluid>
            <Navbar expand="xl" expanded={expanded}>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  
                  <Nav.Link
                    as={NavLink}
                    onClick={() => setExpanded(false)}
                    to="/"
                    className={current_url === "/" ? "active1" : ""}
                  >
                    Downline
                  </Nav.Link>
                  {/* {(user?.userType == "sub_owner" || user?.userType == "owner" || user?.userType == "super_admin") && ( */}
                    <Nav.Link
                      as={NavLink}
                      to="/setting"
                      onClick={() => setExpanded(false)}
                      className={current_url === "setting" ? "active1" : ""}
                    >
                      Setting
                    </Nav.Link>
                    {/* )
                  } */}

                  <NavDropdown title="My Account" id="basic-nav-dropdown">
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/my-account-statement"
                    >
                      {" "}

                      Account Statement

                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/my-account-summary"
                    >
                      {" "}

                      Account Summary

                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/my-profile"
                    >
                      {" "}

                      Profile

                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/my-activity-log"
                    >
                      {" "}

                      Activity Log

                    </NavDropdown.Item>

                  </NavDropdown>

                  <NavDropdown title="My Report" id="basic-nav-dropdown">

                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitMarket"
                    >
                      Profit/Loss Report by Market
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitDownline"
                    >
                  
                       Profit/Loss by downline

                    </NavDropdown.Item>

                    {user?.userType !== "user" && (
                      <>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/AprofitPlayer"
                        >
                          Profit/Loss Report by Player
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/Adownlinesportspl"
                        >
                          Profit/Loss Sports Wise
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/ACdownlinesportspl"
                        >
                          All Casino Profit/Loss
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/AprofitCasino"
                        >
                          Casino Profit/Loss Report by Date
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/ACasinoprofitAndLossDownlineNew"
                        >
                          Casino P/L Downline Monthly
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/ICasinoprofitAndLossDownlineNew"
                        >
                          International Casino P/L Downline Monthly
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/report/awc"
                        >
                          {" "}
                          Profit/Loss AWC Casino Bets
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/report/aura"
                        >
                          {" "}
                          Profit/Loss AURA Casino Bets
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          as={NavLink}
                          to="/report/egt"
                        >
                          {" "}
                          Profit/Loss EGT Casino Bets
                        </NavDropdown.Item>
                      </>
                    )}

                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/loginLogoutReport"
                    >
                      {" "}
                      Login Logout Report
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/recentlyCreatedAccount"
                    >
                      {" "}
                      Recently Created Account
                    </NavDropdown.Item> */}
                  </NavDropdown>
                  
                  <Nav.Link
                    as={NavLink}
                    to="/RiskManagement"
                    onClick={() => setExpanded(false)}
                    className={
                      current_url === "RiskManagement" ? "active1" : ""
                    }
                  >
                    Risk Management
                  </Nav.Link>

                  <Nav.Link
                    as={NavLink}
                    onClick={() => setExpanded(false)}
                    to="/Betlist"
                    className={current_url === "Betlist" ? "active1" : ""}
                  >
                    Bet List
                  </Nav.Link>
                  
                  <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/BetListLive"
                      className={current_url === "BetListLive" ? "active1" : ""}
                    >
                      Live Bet
                  </Nav.Link>

                  <Nav.Link
                    as={NavLink}
                    onClick={() => setExpanded(false)}
                    to="/banking"
                    className={current_url === "banking" ? "active1" : ""}
                  >
                    Banking
                  </Nav.Link>

                   {user?.userType === "owner" || user?.userType === "sub_owner" || user?.userType == "super_admin" ? ( 
                  <Nav.Link
                    as={NavLink}
                    onClick={() => setExpanded(false)}
                    to="/block-market"
                    className={current_url === "block-market" ? "active1" : ""}
                  >
                    Block Market
                  </Nav.Link>
                 ):""} 

                  {(user?.userType == "sub_owner" || user?.userType == "owner" || user?.userType == "super_admin") && (
                    <>
                        <NavDropdown title="Casino Control" id="basic-nav-dropdown">
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/casino/awc"
                          >
                            {" "}
                            AWC Casino Bets
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setExpanded(false)}
                            as={NavLink}
                            to="/casino/aura"
                          >
                            {" "}
                            AURA Casino Bets
                          </NavDropdown.Item>
                          <NavDropdown.Item
                              onClick={() => setExpanded(false)}
                              as={NavLink}
                              to="/casino/egt"
                            >
                              {" "}
                              EGT Casino Bets
                            </NavDropdown.Item>
                          </NavDropdown>
                          
                          <Nav.Link
                              as={NavLink}
                              onClick={() => setExpanded(false)}
                              to="/events"
                              className={current_url === "events" ? "active1" : ""}
                            >
                            MM
                          </Nav.Link>
                        </>
                    )}

               {user?.userType == "owner" || user?.userType == "sub_owner"  || user?.userType == "super_admin"? (
                    <NavDropdown title="Other" id="basic-nav-dropdown">
                      {user?.userType == "owner" || user?.userType == "sub_owner" || user?.userType == "super_admin"?
                      <>
                      <NavDropdown.Item
                        onClick={() => setExpanded(false)}
                        as={NavLink}
                        to="/banner-list"
                      >
                        {" "}
                        Banner
                      </NavDropdown.Item>
                      
                      <NavDropdown.Item
                        onClick={() => setExpanded(false)}
                        as={NavLink}
                        to="/searchuser"
                      >
                        {" "}
                        Search User
                      </NavDropdown.Item>
                  
                     <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/WebsiteSetting"
                    >
                      {" "}
                      Website Setting
                    </NavDropdown.Item>  
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/defaultsetting"
                    >
                      {" "}
                      Default Setting
                    </NavDropdown.Item> 
                    
                   
                  </>:""} 
                    {user?.userType == "owner" || user?.userType == "sub_owner"  || user?.userType == "super_admin" ?
                      <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/user-message"
                    >
                      {" "}
                     User Message
                    </NavDropdown.Item>
                    :""}

                    </NavDropdown>
                  ) : ("")}

                  <Nav.Link
                    as={NavLink}
                    to="/p2p-transfer"
                    onClick={() => setExpanded(false)}
                    className={
                      current_url === "p2p-transfer"
                        ? "active1"
                        : ""
                    }
                  >
                    P2P Transfer

                  </Nav.Link>
                  <NavDropdown title="Payment Transfer" id="basic-nav-dropdown">
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/payment-transfer-setting"
                    >
                      {" "}

                      Payment Transfer Setting

                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/payment-transfer-log"
                    >
                      {" "}

                      Payment Transfer Log

                    </NavDropdown.Item>


                  </NavDropdown>
                  <div className="main-header-time-zone">
                    <ul className="list-unstyled  mb-0">
                      <li>
                        Time Zone : <span>GMT+5:30</span>
                      </li>
                      {/* <li>
                    {isEmpty(user) ? (
                      <Link to="/login">
                        Login <i className="fa-solid fa-right-from-bracket"></i>
                      </Link>
                    ) : (
                      <a
                        style={{ cursor: "pointer" }}
                        as={NavLink}
                        to="#"
                        onClick={logoutUser}
                      >
                        Logout{" "}
                        <i className="fa-solid fa-right-from-bracket"></i>
                      </a>
                    )}
                  </li> */}
                    </ul>
                  </div>
                </Nav>

              </Navbar.Collapse>


            </Navbar>
          </Container>
        </div>
      </header>

      {/* <Button
        variant="primary"
        onClick={handleShow}
        className="color-picker-btn"
      >
        <i className="fa-solid fa-gear"></i>
      </Button> */}

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Change Your Theme</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-center">
                <Form.Label for="primary" className="form-label">
                  Primary Color
                </Form.Label>
                <Form.Control
                  type="color"
                  className="form-control-color m-auto"
                  id="primary"
                  value="#3db39e"
                  title="Choose your color"
                  {...register("primary_color", {
                    required: "Please select color",
                  })}
                />
              </div>

              <div className="text-center">
                <Form.Label for="secondary" className="form-label">
                  Secondary Color
                </Form.Label>
                <Form.Control
                  type="color"
                  className="form-control-color m-auto"
                  id="secondary"
                  value="#060316"
                  title="Choose your color"
                  {...register("secondary_color", {
                    required: "Please select color",
                  })}
                />
              </div>
            </div>
            <button className="btn" type="submit">
              Save
            </button>
            <button className="btn" type="button">
              Reset
            </button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Header;
