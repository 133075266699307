import React, { useState, useEffect,useContext } from "react";
import {
  Container,
  Col,
  Button,
  Row,
  Table,
  Modal,
  Form,
} from "react-bootstrap";import MyAccountSidebar from "../../components/MyAccountSidebar";
import { apiGet, apiPost ,apiPut} from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { pick } from "lodash";
import { useForm } from "react-hook-form";
import Breadcrumbs from "./Breadcrumbs";
import { toast } from "wc-toast";
import { Link } from "react-router-dom";
import { ResetPassword } from "../../components/ResetPassword";
import AuthContext from "../../context/AuthContext";

const MyProfile = () => {
  let { loginUser, user } = useContext(AuthContext);
console.log(user);
  const [profileData, setProfileData] = useState("");
  const [password_same, set_password_same] = useState(true);
  const [isLoader, setLoader] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const changePasswordToggle = () => setChangePassword(!changePassword);
  const { reset } = useForm({});
  const myProfile = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userProfile+"?user_id="+user?._id);
    if (status === 200) {
      if (response_users.success) {
        setProfileData(response_users.results);
      }
    }
  };

  const onSubmit = async (request) => {
    setLoader(true);
    set_password_same(true);

    if (request.newPassword !== request.confirmPassword) {
      setLoader(false);
      set_password_same(false);
    } else {
      const { status, data: response_users } = await apiPost(
        apiPath.changePassword,
        pick(request, ["oldPassword", "newPassword"])
      );
      if (status === 200) {
        if (response_users.success) {
          setLoader(false);
          setChangePassword();
          toast.success(response_users.message);
          reset();
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    }
  };

  useEffect(() => {
    myProfile();
  }, []);
  const [mobile, setMobile] = useState({
    status: false,
    item: "",
  });
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    reset: reset3,
    setValue: setValue3,
    watch: watch3,
  } = useForm({});
  const updateMobile = async (request) => {
   
      // let num = String(880) + String(watch3("phone"));
      let num =  String(watch3("phone"));
      if(String(watch3("phone"))){
        const { status, data: response_users } = await apiPut(
          apiPath.profileUpdate+"/"+profileData?._id,
          { phone: Number(num),
            first_name:profileData?.firstName,
             last_name:profileData?.lastName,
             email:profileData?.email
           }

        );
        if (status === 200) {
          if (response_users.success) {
            toast.success(response_users.message);
            myProfile();
            setMobile({ status: false });
            reset3();
          } else {
            toast.error(response_users.message);
          }
        }else{
          toast.error(response_users.message);
        }
      }else{
        toast.error("Please enter phone number");
      }
     
   
  };
  return (
    <div>
      <div className="user_data_div">
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <Breadcrumbs />
          <Row>
            {/* <Col lg={3}>
              <MyAccountSidebar />
            </Col> */}
            <Col lg={9} md={12}>
              <div className="inner-wrapper">
                {/* profile */}

                <h2>Profile</h2>
                <div className="account-table">
                  <div className="profile-tab table-color">
                    <Row>
                      <Col md={7}>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                colSpan="4"
                                className="text-start"
                              >
                                About You
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start" width="25%">
                                First Name
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.firstName}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Last Name
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.lastName}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-start" width="25%">
                                Birthday
                              </td>
                              <td className="text-start" colSpan="3">
                                -----
                              </td>
                            </tr>

                            <tr>
                              <td className="text-start" width="25%">
                                Email
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.email}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Password
                              </td>
                              <td className="text-start">************</td>
                              <td>
                                <Link
                                  to="#"
                                  className="text-decoration-none btn theme_light_btn"
                                  onClick={changePasswordToggle}
                                >
                                  Edit{" "}
                                  <i className="fas fa-pen ps-1"></i>
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Exposure
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.exposure}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Time Zone
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.timeZone}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>

                      <Col md={5}>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                colSpan="4"
                                className="text-start"
                              >
                                Contact Details
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start">Mobile Number</td>
                              <td className="text-start">
                                {" "}
                                {profileData.phone}
                              </td>
                              <td> <Link
                                  to="#"
                                  className="text-decoration-none text-primary btn theme_light_btn"
                                  onClick={() => {
                                    {
                                      setMobile({
                                        status: true,
                                        item: profileData?.phone || "",
                                      });
                                      setValue3("mobile", profileData?.phone || "");
                                    }
                                  }}
                                >
                                  Edit{" "}
                                  <i className="fas fa-pen text-primary ps-1"></i>
                                </Link></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* profile */}
              </div>
            </Col>
          </Row>
        </Container>
      </section></div>
      {mobile?.status && (
        <Modal
          show={mobile?.status}
          onHide={() => {
            setMobile({ status: false });
          }}
          className="change-status-modal p-0"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title-status h4">
              Edit Phone Number
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="test-status border-0">
              <Form
                className="change-password-sec"
                onSubmit={handleSubmit3(updateMobile)}
              >
                <Form.Group className="d-flex mb-2 flex-column align-items-start">
                  {/* <span
                    style={{
                      fontWeight: "700",
                      fontSize: "13px",
                      marginBottom: "7px",
                    }}
                  >
                    Phone Number
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      background: "#b8b8b8",
                      top: "44%",
                      height: "1.8rem",
                      fontSize: "12px",
                      color: "black",
                      borderRadius: "5px",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 5px",
                    }}
                  >
                    +880
                  </span> */}
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    className={errors3.phone ? " is-invalid " : ""}
                    
                    {...register3("phone", {
                      required: "Please enter phone number",
                    })}
                  />
                  {errors3.phone && errors3.phone.message && (
                    <label className="invalid-feedback align-leftt">
                      {errors3.phone.message}
                    </label>
                  )}
                </Form.Group>

                
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                onClick={() => {
                  setMobile({
                    status: false,
                  });
                }}
                className="btn-light"
              >
                Close
              </Button>
              <Button onClick={() => updateMobile()}>Submit</Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* change-password */}

      <ResetPassword
        changePassword={changePassword}
        changePasswordToggle={changePasswordToggle}
        onSubmit={onSubmit}
        isLoader={isLoader}
        password_same={password_same}
      />
    </div>
  );
};

export default MyProfile;
